var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("豊田")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "mt-3" }, [_vm._v("2021/03/30")]),
          _c("div", { staticClass: "mt-3 text-left" }, [
            _c("p"),
            _c("p", [_vm._v("お久しぶりです。ヤマラボ管理人です。")]),
            _c("p"),
            _c("p", [_vm._v("厳しい冬が終わり、すっかり春めいてきましたね。")]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("ボルダリングシーズンに入ったということで、")]),
            _c("p"),
            _c("p", [_vm._v("2泊3日で岩場に行ってきました！")]),
            _c("p"),
            _c("p", [
              _vm._v("今回行った岩場は、タイトルにある通り、愛知県にある"),
              _c("strong", [_vm._v("【豊田】")]),
              _vm._v("です。")
            ]),
            _c("p"),
            _c("p", [_vm._v("今回撮影したエリアは")]),
            _c("p"),
            _c("p", [_c("strong", [_vm._v("・古美山エリア")])]),
            _c("p", [_c("strong", [_vm._v("・大給城址エリア")])]),
            _c("p", [_c("strong", [_vm._v("・大田エリア")])]),
            _c("p", [_c("strong", [_vm._v("・天下峰エリア")])]),
            _c("p"),
            _c("p", [_vm._v("です。")]),
            _c("p", [_vm._v("(いずれも、ボルダリングする岩のみ撮影)")]),
            _c("p"),
            _c("p", [_vm._v("１日目は、古美山エリアへ。")]),
            _c("p"),
            _c("p", [
              _vm._v("古美山エリアは、駐車場からのアプローチが大変しやすく、")
            ]),
            _c("p"),
            _c("p", [_vm._v("徒歩1分ですぐ岩に到着できます。")]),
            _c("p"),
            _c("p", [_vm._v("駐車場には、トイレもありました。")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/komiyama.jpg", alt: "" }
            }),
            _c("p", [_vm._v("古美山エリア駐車場")]),
            _c("p"),
            _c("p", [_vm._v("1つ1つの岩が大きくわかりやすかったです。")]),
            _c("p"),
            _c("p", [
              _vm._v("練習になりそうなトップロープの課題もありました。")
            ]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("2日目は、大給城址エリアへ。")]),
            _c("p"),
            _c("p", [_vm._v("こちらのエリアは、駐車場はないものの、")]),
            _c("p"),
            _c("p", [
              _vm._v("道路わきに、4台くらい駐車できるスペースがありました。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v("大給城址エリアも、アプローチはそれほどかからず、")
            ]),
            _c("p"),
            _c("p", [_vm._v("歩いて5分程度で岩場に到着できました。")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/ogyujyoshi.jpg", alt: "" }
            }),
            _c("p", [_vm._v("大給城址エリア入口付近")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "岩と岩との距離がそんなにないので、岩を見つけやすいと思います。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "ですが、自然に返っている岩も数個あったので、登る時は清掃が必要そうです。"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("次に、大田エリアへ行きました。")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/oota1.jpg", alt: "" }
            }),
            _c("p", [_vm._v("大田エリア駐車場")]),
            _c("p"),
            _c("p", [_vm._v("歌石駐車場という大きい駐車場がありました。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "下段の駐車場は、観光客やハイカーのために駐車しないようご協力よろしくお願いします。"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("(過去に、地域の方から連絡があったようです。)")]),
            _c("p"),
            _c("p", [
              _vm._v("クライマーの皆さんは、上段の駐車場を利用しましょう。")
            ]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/oota2.jpg", alt: "" }
            }),
            _c("p", [_vm._v("大田エリア入口付近")]),
            _c("p"),
            _c("p", [_vm._v("大田エリアも駐車場からのアプローチがしやすく、")]),
            _c("p"),
            _c("p", [_vm._v("駐車場から徒歩5分ほどで岩場に到着できました。")]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("3日目は天下峰エリアに行きました。")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/tengamine1.jpg", alt: "" }
            }),
            _c("p", [_vm._v("天下峰エリア駐車場")]),
            _c("p"),
            _c("p", [_vm._v("4台くらい駐車できるスペースがありました。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "天下峰もアプローチが大変しやすく、徒歩3分ほどで、岩場に到着できました。"
              )
            ]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210329/tengamine2.jpg", alt: "" }
            }),
            _c("p"),
            _c("p", [_vm._v("天下峰はトップロープ課題の岩が多くあります。")]),
            _c("p"),
            _c("p", [
              _vm._v("整備をしっかりされており、岩も見つけやすいと思います。")
            ]),
            _c("p"),
            _c("p", [_vm._v("予定していたエリアの調査は全て終わり、")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "その後は、次行くエリアの駐車場を探してから帰路につきました。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "今回行った4エリアは全て、車を駐車できるスペースもあり、アプローチが大変しやすかったです。"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("また、豊田のオフィシャルブログがあります。")]),
            _c("p"),
            _c("p", [_vm._v("最新の情報など掲載されているので、")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "岩場に行く時は、こちらのサイトをチェックすると良いと思います。"
              )
            ]),
            _c("p"),
            _c(
              "a",
              {
                attrs: {
                  href: "http://toyotanoiwaba.info/",
                  target: "_blank",
                  rel: "noopener noreferrer"
                }
              },
              [_vm._v("豊田の岩場［オフィシャルブログ］")]
            ),
            _c("p"),
            _c("p", [_vm._v("これから、豊田の岩場に行かれる方は")]),
            _c("p"),
            _c("p", [_vm._v("ルールを守って、ボルダリングを楽しみましょう！")]),
            _c("p"),
            _c("p", [
              _vm._v("それでは、トポ作成でき次第こちらでお知らせします！")
            ]),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }